<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            

    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="매출 등록">
            <div class="sales-text">
                    ※ 일반발행은 당일 or 익일 발행 (16시 이후 올릴 시 익일 처리)<br/>
                    ※ 긴급발행은 당일 발행 (16시 이후 올릴 시 익일 처리)<br/>
                    ※ 모든 계산서는 팀장승인 후 발행 합니다.
                </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 구분</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="comp.info.corp" value="캐럿글로벌"><span class="ml-5 mr-20"> 캐럿글로벌</span></label>
                                    <label><input type="radio" v-model="comp.info.corp" value="캐럿에듀"><span class="ml-5 mr-20"> 캐럿에듀</span></label>
                                    <!-- <label><input type="radio" v-model="comp.info.corp" value="캐럿에듀플러스"><span class="ml-5 mr-20"> 캐럿에듀플러스</span></label>-->
                                    <label><input type="radio" v-model="comp.info.corp" value="캐럿솔루션즈"><span class="ml-5 mr-20"> 캐럿솔루션즈</span></label>
                                    <label><input type="radio" v-model="comp.info.corp" value="밝음"><span class="ml-5 mr-20"> 밝음</span></label>
                                    <label><input type="radio" v-model="comp.info.corp" value="모리와함께"><span class="ml-5 mr-20"> 모리와함께</span></label>
                                    <!-- <label><input type="radio" v-model="comp.info.corp" value="더세라베어"><span class="ml-5 mr-20"> 더세라베어</span></label> -->
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 매출형태</th>
                                <td colspan="3">
                                    <select v-model="comp.info.stype" class="w-200px">
                                        <option value="">선택</option>
                                        <option value="계산서">계산서</option>
                                        <option value="세금계산서">세금계산서</option>
                                        <option value="카드결제">카드결제</option>
                                        <option value="개인입금">개인입금</option>
                                        <option value="RF">RF</option>
                                        <option value="수출">수출</option>
                                    </select>
                                </td>
                            </tr>
                            <tr >
                              <th><span class="txt-red"></span> 팀장선택</th>
                              <td colspan="3" v-if="comp.info.idx== 0 || comp.info.sign_step== '발행요청' ">
                                <select v-model="comp.info.team_lead" class="w-200px">
                                  <option :value="0">선택</option>
                                  <option :value="v.idx" v-for="(v, k) in comp.lead_list" :key="k">{{ v.ename }} ({{ v.kname }}) </option>
                                </select>
                              </td>
                              <td colspan="3" v-else >
                                {{  comp.info.idx_sign_allow_name}}
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 매출발생일</th>
                                <td colspan="3">
                                    <CarrotDatePicker v-model="comp.info.cdate"></CarrotDatePicker>
                                  &nbsp;
                                  <label><input type="checkbox" v-model="comp.info.emergency" >

                                    <span class="ml-5 mr-20">긴급 발행 건
                                      <span style="color:red">* 꼭 긴급 발행이 필요할 때만 체크해 주세요.</span>
                                    </span>

                                  </label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <CarrotCompany bizno="Y" @update:bizno="comp.setBizNo" v-model="comp.info.idx_company" class="w-300px"></CarrotCompany>
                                </td>
                                <th>사업자번호</th>
                                <td>{{comp.info.bizno}}</td>
                            </tr>
                            <tr>
                                <th>클래스 선택</th>
                                <td colspan="3">
                                    <div class="dp-table w-100per">
                                        <input v-model="comp.search.classname" type="text" class="w-300px float-left" placeholder="클래스명을 입력해 주세요">
                                        <button class="btn-default h-30px float-left ml-10" @click="comp.findClass()">검색</button>
                                    </div>
                                    <div class="dp-table w-100per mt-10">
                                        <div class="borderDDD-box pt-5 pl-10 pr-10 mb-10">
                                            <label><input type="checkbox" v-model="comp.checkAll" @change="comp.setCheckAll()"><span class="ml-5 mr-20"> 전체선택({{comp.search.classlist.length}})</span></label>
                                        </div>
                                        <div class="borderDDD-box pt-5 pl-10 pr-10 overhidden overscroll h-135px">
                                            <ul>
                                                <li v-for="(c,idx) in comp.search.classlist" :key="idx">
                                                    <label><input type="checkbox" name="classid" v-model="comp.selectedClass" :value="idx"><span class="ml-5 mr-20"> {{c.classname}}({{c.sdate}} ~ {{c.edate}}) - {{c.tutor_ename}}</span></label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dp-table w-100per txt-center mt-10">
                                        <button @click="comp.addSelected()" class="btn-default dp-inblock">선택 ▼</button>
                                    </div>
                                    <div class="dp-table w-100per mt-15">
                                        <div><span class="font-13">선택</span><span class="ml-5 font-13 bold txt-blue">{{comp.info.list_class.length}}</span></div>
                                        <div class="borderDDD-box pt-5 pl-10 pr-10 overhidden overscroll h-135px">
                                            <ul class="hasDelR-list">
                                                <li v-for="(c, idx) in comp.info.list_class" :key="idx">
                                                    <span class="txt">{{c.classname}}({{c.sdate}} ~ {{c.edate}}) - {{c.tutor_ename}}</span><button @click="comp.delClass(idx)" class="btn-del">X</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내역</th>
                                <td colspan="3">
                                    <input v-model="comp.info.detail" type="text" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 금액</th>
                                <td>
                                  <input type="number" v-model.trim="comp.info.price"  class="w-80per">
                                    <!--<CarrotCostInput v-model="comp.info.price" class="w-80per"></CarrotCostInput>-->
                                    <span class="ml-10">원</span>
                                    <div class="mt-5 color-1">* 부가세를 제외한 매출액을 입력해주세요.</div>
                                </td>
                                <th>자신의 금액<br>(금액 – RF금액)</th>
                                <td class="txt-center">{{comp.getMyCost()}}원</td>
                            </tr>
                            <tr>
                                <th>RF</th>
                                <td colspan="3">
                                    <div>
                                        <CarrotStaff title="매니저 선택"  class="w-200px" v-model="comp.info.rf1_staff"></CarrotStaff>
                                        <CarrotCostInput v-model="comp.info.rf1_cost" class="w-200px ml-10"></CarrotCostInput>
                                        <span class="ml-10">원</span>
                                    </div>
                                    <div class="mt-10">
                                        <CarrotStaff title="매니저 선택"  class="w-200px" v-model="comp.info.rf2_staff"></CarrotStaff>
                                        <CarrotCostInput v-model="comp.info.rf2_cost" class="w-200px ml-10"></CarrotCostInput>
                                        <span class="ml-10">원</span>
                                    </div>
                                    <div class="mt-10">
                                        <CarrotStaff title="매니저 선택"  class="w-200px" v-model="comp.info.rf3_staff"></CarrotStaff>
                                        <CarrotCostInput v-model="comp.info.rf3_cost" class="w-200px ml-10"></CarrotCostInput>
                                        <span class="ml-10">원</span>
                                    </div>
                                    <div class="mt-10">
                                        <CarrotStaff title="매니저 선택"  class="w-200px" v-model="comp.info.rf4_staff"></CarrotStaff>
                                        <CarrotCostInput v-model="comp.info.rf4_cost" class="w-200px ml-10"></CarrotCostInput>
                                        <span class="ml-10">원</span>
                                    </div>
                                    <div class="mt-10">
                                        <CarrotStaff title="매니저 선택"  class="w-200px" v-model="comp.info.rf5_staff"></CarrotStaff>
                                        <CarrotCostInput v-model="comp.info.rf5_cost" class="w-200px ml-10"></CarrotCostInput>
                                        <span class="ml-10">원</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 특이사항</th>
                                <td colspan="3">
                                    <textarea class="w-100per h-100px" v-model="comp.info.memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th> 첨부파일</th>
                                <td colspan="3">
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 업체 정산담당자</th>
                                <td colspan="3">
                                    <div><span class="ver-middle">이름 : </span><input v-model="comp.info.clac_name" type="text" class="w-200px ml-5 ver-middle"></div>
                                    <div class="mt-10"><span class="ver-middle">연락처 : </span><input v-model="comp.info.calc_tel" type="text" class="w-200px ml-5 ver-middle"></div>
                                    <div class="mt-10"><span class="ver-middle">이메일 주소 : </span><input v-model="comp.info.calc_email" type="text" class="w-300px ml-5 ver-middle"></div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 발행상태</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="comp.info.state" value="정발행"><span class="ml-5 mr-20"> 정발행</span></label>
                                    <label><input type="radio" v-model="comp.info.state" value="역발행"><span class="ml-5 mr-20"> 역발행</span></label>
                                    <span class="ml-10 color-1">* 역발행일 경우 역발행 체크해주세요.</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <router-link to="/documents/SalesManagementPublishList"><button class="btn-default float-left mt-30">취소</button></router-link>
                    <button class="btn-default float-right mt-30" @click="comp.add">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
        </div>
    </div>

</template>


<script>
// @ is an alias to /src CustomerSearchPopup
import { onMounted, reactive, ref } from 'vue'
// import CustomerSearchPopup from '@/components/popup/payment/CustomerSearchPopup.vue'
import CarrotCostInput from '@/components/input/CarrotCostInput.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import CarrotCompany from '@/components/common/CarrotCompany.vue';
import CarrotStaff from '@/components/common/CarrotStaff.vue';

import axios from '@/plugins/axios.js'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import Swal from 'sweetalert2';
import {useStore} from "vuex";

export default {
    components: {
        // CustomerSearchPopup,
        CarrotCostInput,
        CarrotDatePicker,
        CarrotCompany,
        CarrotStaff
    },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const route = useRoute();
        const store = useStore()

        const file1 = ref(null);
        const file3 = ref(null);
        const file2 = ref(null);
        const comp = reactive({
            // Popup 노출 여부
            isShow: true,

            fuel : [{value:1510, label: "휘발유(1,510)"}],
            info : {
                idx : 0,
                corp : "캐럿글로벌",
                stype : "",
                cdate : "",
                idx_company : 0,
                bizno : "",
                list_class : [],
                detail : "",
                price : "",
                rf1_staff : 0,
                rf1_cost : "",
                rf2_staff : 0,
                rf2_cost : "",
                rf3_staff : 0,
                rf3_cost : "",
                rf4_staff : 0,
                rf4_cost : "",
                rf5_staff : 0,
                rf5_cost : "",
                memo : "",
                clac_name : "",
                calc_tel : "",
                calc_email : "",
                state : "",
                file1_name : "",
                file1_url : "",
                file2_name : "",
                file2_url : "",
                file3_name : "",
                file3_url : "",
                emergency:false ,
                team_lead:0,
            },
            search : {
                classname : "",
                classlist : [],
            },
            selectedClass : [],
            checkAll : false,
            lead_list:[],
            setCheckAll() {
                if(comp.checkAll) {
                    comp.selectedClass = [];
                    for(let i=0;i<comp.search.classlist.length;i++) {
                        comp.selectedClass.push(i);
                    }
                } else {
                    comp.selectedClass = [];
                }
            },
            intToCost(price) {
                let cost = price.toString().replace(/,/g, "");
                cost = parseFloat(cost);
                if(isNaN(cost)) {
                    cost = 0;
                }
                return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            setBizNo : (bizno) => {
                comp.info.bizno = bizno;
            },
            getMyCost : () => {
                return comp.intToCost(Number(comp.info.price) - Number(comp.info.rf1_cost) - Number(comp.info.rf2_cost) - Number(comp.info.rf3_cost) - Number(comp.info.rf4_cost) - Number(comp.info.rf5_cost));
            },
            findClass: () => {
                let param = {
                    tmr: new Date(),
                    classname:comp.search.classname,
                    companycode:comp.info.idx_company,
                };
                if(window.location.href.indexOf("stage") > -1){
                  axios.get('https://stageitm.carrotenglish.net/rest/carrotians/get_classlist_by_info', {params: param}).then((res) => {
                    if(res.data.err == 0) {
                      comp.selectedClass = [];
                      comp.search.classlist = res.data.list;
                    }
                  });
                }else{
                  axios.get('https://itm.carrotenglish.net/rest/carrotians/get_classlist_by_info', {params: param}).then((res) => {
                    if(res.data.err == 0) {
                      comp.selectedClass = [];
                      comp.search.classlist = res.data.list;
                    }
                  });
                }
            },

            addSelected : () => {
                let check = false;
                for(let i=0;i<comp.selectedClass.length;i++) {
                    check = true;
                    for(let j=0;j<comp.info.list_class.length;j++) {
                        if(comp.search.classlist[comp.selectedClass[i]].classid==comp.info.list_class[j].classid) {
                            check = false;
                        }
                    }
                    if(check) {
                        comp.info.list_class.push(comp.search.classlist[comp.selectedClass[i]]);
                    }
                }
            },
            delClass : (idx) => {
                comp.info.list_class.splice(idx, 1);
            },
            checkValidation : () => {
                if(comp.info.idx_company<=0) {
                    toast.error("회사명은 필수 입력사항입니다.");
                    return false;
                }
                if(comp.info.cdate=='') {
                    toast.error("매출발생일은 필수 입력사항입니다.");
                    return false;
                }
                if(comp.info.stype=='') {
                    toast.error("매출형태는 필수 입력사항입니다.");
                    return false;
                }
                if(comp.info.state=='') {
                    toast.error("발행상태는 필수 입력사항입니다.");
                    return false;
                }
                if(comp.info.clac_name=='' || comp.info.calc_tel=='' || comp.info.calc_email=='') {
                    toast.error("업체 정산담당자 정보는 필수 입력사항입니다.");
                    return false;
                }
                /*
                if(comp.info.price<=0) {
                    toast.error("금액을 확인해주세요.");
                    return false;
                }

                 */

                if(comp.info.detail=='') {
                    toast.error("내역은 필수 입력사항입니다.");
                    return false;
                }

                if(comp.info.memo=='') {
                    toast.error("특이사항은 필수 입력사항입니다.");
                    return false;
                }

                return true;
            },
            add : () => {
                if(!comp.checkValidation()) {
                    return;
                }
                Swal.fire({
                    title : "매출 등록",
                    text : "등록하시겠습니까?",
                    showCancelButton : true,
                    confirmButtonText : "확인",
                    cancelButtonText : "취소",
                }).then((result) => {
                    if (result.isConfirmed) {

                        var formData = new FormData();


                        formData.append("idx", comp.info.idx);
                        formData.append("corp", comp.info.corp);
                        formData.append("stype", comp.info.stype);
                        formData.append("cdate", comp.info.cdate);
                        formData.append("idx_company", comp.info.idx_company);
                        formData.append("list_class", JSON.stringify(comp.info.list_class));
                        formData.append("detail", comp.info.detail);
                        formData.append("price", comp.info.price);
                        formData.append("rf1_staff", comp.info.rf1_staff);
                        formData.append("rf1_cost", comp.info.rf1_cost);
                        formData.append("rf2_staff", comp.info.rf2_staff);
                        formData.append("rf2_cost", comp.info.rf2_cost);
                        formData.append("rf3_staff", comp.info.rf3_staff);
                        formData.append("rf3_cost", comp.info.rf3_cost);
                        formData.append("rf4_staff", comp.info.rf4_staff);
                        formData.append("rf4_cost", comp.info.rf4_cost);
                        formData.append("rf5_staff", comp.info.rf5_staff);
                        formData.append("rf5_cost", comp.info.rf5_cost);
                        formData.append("memo", comp.info.memo);
                        formData.append("clac_name", comp.info.clac_name);
                        formData.append("calc_tel", comp.info.calc_tel);
                        formData.append("calc_email", comp.info.calc_email);
                        formData.append("state", comp.info.state);

                        if(file1.value.file.filedata!==null)
                            formData.append("file1", file1.value.file.filedata);
                        if(file2.value.file.filedata!==null)
                            formData.append("file2", file2.value.file.filedata);
                        if(file3.value.file.filedata!==null)
                            formData.append("file3", file3.value.file.filedata);
                        if(comp.info.emergency==true)
                          formData.append("emergency", 'Y');
                        if(comp.info.emergency==false)
                          formData.append("emergency", 'N');

                        formData.append("file1_name", comp.info.file1_name);
                        formData.append("file1_url", comp.info.file1_url);
                        formData.append("file2_name", comp.info.file2_name);
                        formData.append("file2_url", comp.info.file2_url);
                        formData.append("file3_name", comp.info.file3_name);
                        formData.append("file3_url", comp.info.file3_url);
                        formData.append("team_lead", comp.info.team_lead);

                        axios.post('/rest/sales/addSales', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data'
                            }
                        }).then((res)=>{
                            if( res.data.err == 0 ){
                                router.push({ 
                                    name:"documents-SalesManagementPublishList",
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });

                    }

                });

            },
            isEmpty : (str) => {
                if(typeof str == "undefined" || str == null || str == "")
                    return true;
                else
                    return false ;
            },
            getSalesInfo : (idx) => {
                let params = {
                    idx : idx
                };
                axios.get('/rest/sales/getSalesInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.info = res.data.info;
                        comp.info.list_class = JSON.parse(res.data.info.list_class);
                        file1.value.file.filename = res.data.info.file1_name;
                        file2.value.file.filename = res.data.info.file2_name;
                        file3.value.file.filename = res.data.info.file3_name;

                        comp.info.file1_name = res.data.info.file1_name;
                        comp.info.file1_url = res.data.info.file1_url;
                        comp.info.file2_name = res.data.info.file2_name;
                        comp.info.file2_url = res.data.info.file2_url;
                        comp.info.file3_name = res.data.info.file3_name;
                        comp.info.file3_url = res.data.info.file3_url;
                        comp.info.team_lead =res.data.info.idx_sign_allow;

                        if(res.data.info.emergency=='Y'){
                          comp.info.emergency=true;
                        }else{
                          comp.info.emergency==false
                        }

                    } else {
                        if(res.data.err==201) {
                            router.push({ 
                                name:"documents-SalesManagementPublishList"
                            });
                            return;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    }
                });
            },
            getTeamLeaderList : () => {

             axios.get('/rest/sales/teamLeaderList', ).then((res) => {
              if( res.data.err == 0 ){
                comp.lead_list = res.data.list;
              }
            });
          },

        });

        onMounted(() => {
            // Mounted
            if(!comp.isEmpty(route.params.id)) {
                comp.info.idx = route.params.id;
                comp.getSalesInfo(comp.info.idx);

            }
          comp.getTeamLeaderList();
        });

        return {comp, file1, file2, file3, store};
    }
}
</script>


<style lang="scss" scoped>
.sales-text{
    position: absolute;
    top: 2px;
    margin-left : 90px;
    color: rgb(255, 53, 53);
}
</style>